@font-face {
  font-family: 'Futura PT Book';
  src: url('./fonts/Futura/FuturaPTBook.otf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Futura PT Bold';
  src: url('./fonts/Futura/FuturaPTBold.otf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Duplet";
  src: local("Duplet"), url("./fonts/dupletRegular.woff") format("woff");
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
  padding: 10px;
  font-family: 'Duplet';
}

.brightline-font {
  font-family:'Futura PT Book';
  font-style: normal;
  font-size: medium;
  font-weight: 300;
}
.header-font {
  font-family: 'Futura PT Bold';
  font-weight: 600;
  color: #6b6c70;
}

.zippin-font {
  font-family: 'Duplet';
}
.title-font {
  color: #888a8f;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  /* height: 40vmin; */
  pointer-events: none;
}

.App-header {
  background-color: 'none';
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-self: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #ff5c39;
}

.title {
    font-size: 14px;
  }

  
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
